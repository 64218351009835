import { useState, useContext, useEffect } from "react";
import { DAppContext } from "../../context";
import Metamask from "../../assets/metamask.svg";
import WalletConnectImage from "../../assets/walletConnect.svg";
import MinterVideo from "../../assets/mint.mp4";
import "./index.css";

const ConnectWalletModal = () => {
  const { loading, connectBrowserWallet, connectToWalletConnect } =
    useContext(DAppContext);

  const handleWalletLogin = (e) => {
    const { proxy } = e.currentTarget.dataset;

    if (proxy === "walletConnect") {
      connectToWalletConnect();

      return;
    }
    connectBrowserWallet();
  };

  return (
    <div className="wallet--connection-modal">
      <div className="wallet--connection-modal_item_wrapper">
        <div
          onClick={handleWalletLogin}
          data-proxy="browserWallet"
          className="wallet--connection-modal_item"
        >
          <div className="wallet--connection-modal_img">
            <img src={Metamask} alt="" />
          </div>
          <h3 className="wallet--connection-modal_provider">Metamask</h3>
          <p className="wallet--connection-modal_description">
            Connect to your MetaMask Wallet
          </p>
        </div>
        <div
          data-proxy="walletConnect"
          className="wallet--connection-modal_item"
          onClick={handleWalletLogin}
        >
          <div className="wallet--connection-modal_img">
            <img src={WalletConnectImage} alt="" />
          </div>
          <h3 className="wallet--connection-modal_provider">WalletConnect</h3>
          <p className="wallet--connection-modal_description">
            Scan with WalletConnect to connect
          </p>
        </div>
        {loading && <div className="loader">Fetching...</div>}
      </div>
    </div>
  );
};

const Minter = () => {
  const { userData, mint } = useContext(DAppContext);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (userData?.account) {
      setShowModal(false);
    }
  }, [userData?.account]);

  return (
    <div className="connect-btn-container">
      <h1>
        guardians of <br /> sumantria
      </h1>
      <video
        id="myVideo"
        autoPlay='autoPlay'
        playsInline
        muted
        loop
        className="video-container"
      >
        <source   src={MinterVideo} type="video/mp4"   />
        </video>
      
      {!userData ? (
        <button type="button" onClick={toggleModal} className="connect-wallet">
          Connect Wallet
        </button>
      ) : (
        <div className="minters">
          <button type="button" onClick={() => mint(1)} className="mint-btn">
            Mint 1
          </button>
          <button type="button" onClick={() => mint(2)} className="mint-btn">
            Mint 2
          </button>
          <button type="button" onClick={() => mint(5)} className="mint-btn">
            Mint 5
          </button>
        </div>
      )}
      {showModal && (
        <>
          <ConnectWalletModal setShowModal={setShowModal} />
          <div className="wallet--connection-modal_overlay" />
        </>
      )}
      <p className="copyright">&copy;2022 Sumantria</p>
      <div className="socials-container">
        {/* <a href="#" className="social--item" target="_blank" rel="noreferrer">
          <i class="fa-brands fa-discord"></i>
        </a>
        <a href="#" className="social--item" target="_blank" rel="noreferrer">
          <i class="fa-brands fa-instagram"></i>
        </a>
        <a href="#" className="social--item" target="_blank" rel="noreferrer">
          <i class="fa-brands fa-twitter"></i>
        </a> */}
      </div>
    </div>
  );
};

export default Minter;
